gsap.registerPlugin(ScrollTrigger);

class Farbwechsler {
    constructor({ trigger, text, containerText, slider, buttons }) {
        this.trigger = trigger ? document.querySelector(trigger) : null;
        this.text = text ? document.querySelector(text) : null;
        this.containerText = containerText ? document.querySelector(containerText) : null;
        this.slider = slider ? document.querySelector(slider) : null;
        this.buttons = buttons ? document.querySelectorAll(buttons) : null;
    }

    get sliderWidth() {
        return (this.slider.clientWidth - window.innerWidth);
    }
    get textTop() {
        return (this.text.getBoundingClientRect().top);
    }
    get containerTextHeight() {
        return (this.containerText.getBoundingClientRect().height);
    }
    // Wenn die Seite bereits bis in den oder über den Slider hinaus gescrollt ist, stimmten die start/stop Positionen des Farbwechsler nicht.
    // horizontalScrollOffset berechnet die Anzahl Pixel, die der Slider bereits gescrollt ist.
    get horizontalScrollOffset() {
        let slider = ScrollTrigger.getById('slider');
        return slider.progress * (slider.end - slider.start) * -1;
    }

    farbeWechselnTextMitContainer() {
        if (!this.trigger || !this.text || !this.containerText) return;

        if (this.slider) {
            gsap.timeline({
                scrollTrigger: {
                    trigger: this.trigger,
                    start: () => `${(this.textTop + this.containerTextHeight - this.sliderWidth - this.horizontalScrollOffset) * -1} top`,
                    end: () => `${(this.textTop - this.sliderWidth - this.horizontalScrollOffset) * -1} top`,
                    scrub: true,
                    invalidateOnRefresh: true
                }
            }).to(this.containerText, {
                immediateRender: true,
                ease: 'none',
                backgroundPositionY: '100%'
            }, 0);
        } else {
            gsap.timeline({
                scrollTrigger: {
                    trigger: this.trigger,
                    start: () => `${((this.textTop + this.containerTextHeight)) * -1} top`,
                    end: () => `${(this.textTop) * -1} top`,
                    scrub: true,
                    invalidateOnRefresh: true,
                }
            }).to(this.containerText, {
                immediateRender: true,
                ease: 'none',
                backgroundPositionY: '100%'
            }, 0);
        }
    }

    farbeWechselnText() {
        if (!this.trigger || !this.buttons) return;

        if (this.slider) {

            this.buttons.forEach((button) => {
                gsap.to(button, {
                    scrollTrigger: {
                        trigger: this.trigger,
                        start: () => `${(button.getBoundingClientRect().top + button.getBoundingClientRect().height - this.sliderWidth - this.horizontalScrollOffset) * -1} top`,
                        end: () => `${(button.getBoundingClientRect().top - this.sliderWidth - this.horizontalScrollOffset) * -1} top`,
                        scrub: true,
                        invalidateOnRefresh: true,
                    },
                    backgroundPositionY: '100%',
                    ease: 'none',
                });
            });
        } else {
            this.buttons.forEach((button) => {
                gsap.to(button, {
                    scrollTrigger: {
                        trigger: this.trigger,
                        start: () => `${(button.getBoundingClientRect().top + button.getBoundingClientRect().height) * -1} top`,
                        end: () => `${(button.getBoundingClientRect().top) * -1} top`,
                        scrub: true,
                        invalidateOnRefresh: true,
                    },
                    backgroundPositionY: '100%',
                    ease: 'none',
                });
            });
        }
    }


    killTimeline() {
        if (this.timeline) {
            this.timeline.kill();
            this.timeline = null;
        }
    }
}